import { Component, OnInit } from '@angular/core';
import { question_content, IQuestionContent, answer_content, IAnswerContent, verbage_content, IVersionContent } from '../_model/faq_content';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css', '../home/font.css']
})
export class FaqComponent implements OnInit {
  
  lang_type:string = "en";

  question_one:string = "";
  question_two:string = "";
  question_three:string = "";
  question_four:string = "";

  answer_one:string = "";
  answer_two:string = "";
  answer_three:string = "";
  answer_four:string = "";

  version_verbage:string = "";


  constructor() { }

  ngOnInit(): void {


  	const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    // console.log(baseUrl); // this will print http://example.com or http://localhost:4200

    // console.log('window host')
    // console.log (window.location.host);

    let baseHost = window.location.host;

  	// Get Language Tag (from domain url)
  	

  	// Determine language
    if (baseHost.includes("en-es")) {
      this.lang_type = 'es'
    } else if (baseHost.includes("es.hathlete.com")) {
      this.lang_type = 'es' 
    } else if (baseHost.includes("en-fr")) {
      this.lang_type = 'fr' 
    } else if (baseHost.includes("fr.hathlete.com")) {
      this.lang_type = 'fr' 
    } else if (baseHost.includes("en-pt")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("pt.hathlete.com")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("en-de")) {
      this.lang_type = 'de' 
    } else if (baseHost.includes("de.hathlete.com")) {
      this.lang_type = 'de' 
    } else if (baseHost.includes("en-pt")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("pt.hathlete.com")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("en-cn")) {
      this.lang_type = 'cn' 
    } else if (baseHost.includes("cn.hathlete.com")) {
      this.lang_type = 'cn' 
    } else if (baseHost.includes("en-ru")) {
      this.lang_type = 'ru' 
    } else if (baseHost.includes("ru.hathlete.com")) {
      this.lang_type = 'ru' 
    } else if (baseHost.includes("en-jp")) {
      this.lang_type = 'jp' 
    } else if (baseHost.includes("jp.hathlete.com")) {
      this.lang_type = 'jp' 
    } else if (baseHost.includes("en-in")) {
      this.lang_type = 'in' 
    } else if (baseHost.includes("in.hathlete.com")) {
      this.lang_type = 'in' 
    } else if (baseHost.includes("en-ar")) {
      this.lang_type = 'ar' 
    } else if (baseHost.includes("ar.hathlete.com")) {
      this.lang_type = 'ar' 
    } else {
      this.lang_type = 'en'
    }

  	//  Get Content
	var dictQContent: Record<string, Partial<IQuestionContent>> = question_content;
	var dictAContent: Record<string, Partial<IAnswerContent>> = answer_content;

	var dictVContent: Record<string, IVersionContent> = verbage_content;

	// Populate Content
	this.question_one = String(dictQContent[this.lang_type].question_one);
	this.question_two = String(dictQContent[this.lang_type].question_two);
	this.question_three = String(dictQContent[this.lang_type].question_three);
	this.question_four = String(dictQContent[this.lang_type].question_four);

	this.answer_one = String(dictAContent[this.lang_type].answer_one);
	this.answer_two = String(dictAContent[this.lang_type].answer_two);
	this.answer_three = String(dictAContent[this.lang_type].answer_three);
	this.answer_four = String(dictAContent[this.lang_type].answer_four);

	this.version_verbage = String(dictVContent[this.lang_type].version_verbage);

  }

}
