


<app-header></app-header>

 <div id="wrap">
        <!--[if lt IE 7]>
            <p class="chromeframe">You are using an outdated browser. <a href="http://browsehappy.com/">Upgrade your browser today</a> or <a href="http://www.google.com/chromeframe/?redirect=true">install Google Chrome Frame</a> to better experience this site.</p>
        <![endif]-->

    <!-- START HEADER  -->
        <div class="header-container">
            <header class="wrapper clearfix">


                <!-- START SLIDER -->

                      <div id="header" >

                    <!-- START SLIDE BUTTONS  -->
                    <div class="prevNext">
                      <img class="prev" src="/assets/landing/img/prev.png" alt="prev" />
                      <img class="next" src="/assets/landing/img/next.png" alt="next" />
                    </div>



                    <div id="sequence">
                      <ul>
                        <!-- START SLIDE 1 -->
                        <li>
                              <h2 [ngClass]="{'title animate-in one':true, 'mbtm75' : isMobile}"> 
                                <span>{{slide_one_title}}</span>
                              </h2>

                            <!--  
                            <h2 *ngIf="isMobile" class="title animate-in one">
                                        <span>{{slide_one_title}}Y</span>
                            </h2>


                            <div style="text-align: center;">
                              <h2 class="title animate-in one" style="text-align: center;">
                                          <span>{{slide_one_title}}X</span>
                              </h2>
                            </div>
                            -->




                                <div class="subtitle animate-in one">
                                        <span>{{slide_one_desc}}</span>
                                        <!--
                                        <py:if test="lng=='fr'">${txtual_landing.indx_phone_text_fr()}</py:if>
                                        -->
                                      


                                      <h5 *ngIf="!isMobile">{{slide_one_coming_soon}}</h5>
                                      <ol *ngIf="!isMobile">
                                                                  <li><img src="/assets/landing/img/apple.png" alt="Apple App Store" /></li>
                                                                  <li><img src="/assets/landing/img/android.png" alt="Android App Store" /></li>
		                                                             <!--
		                                                            <li><a href="apps.hathlete.com/beta" target="_blank">
		                                                                <img src="landing/img/windows.png" alt="Windows App Store" />
		                                                            </a></li>
		                                                            -->
                                      </ol>




                                      <h3>{{slide_one_beta}}</h3>

                                        <div *ngIf="userEmailSent==false">
                                          <input type="text" name="test" placeholder="you@email.com" [(ngModel)]="userEmailOne" style="width:250px;padding-left:10px;" />
                                          &nbsp;<button type="button" (click)="userBetaRegistration('userEmailOne')" class="btn btn-primary">{{slide_one_beta_button}}</button>
                                        </div>

                                        <div>
                                          <span class="sent_msg">{{thank_you_message}}</span>
                                        </div>

                                </div>
                                        <img class="slide one animate-in" src="{{slide_one_image}}" alt="iPhone 14 Pro Max in hand" />
                             			<!-- <img class="slide one animate-in" src="landing/img/slide1e.png" alt="iPhone 5 in hand" /> -->
                        </li>


                        <!-- START SLIDE 5 -->
                        <li>
                          <h2 [ngClass]="{'title five':true, 'mbtm75' : isMobile}">
                          				{{slide_two_title}}                                        
                                    </h2>
                                                                  
                                    <div class="subtitle five">
                                    	{{slide_two_desc}}
                                        
                                        <h5 *ngIf="!isMobile">{{slide_two_coming_soon}}</h5>

                                        <ol *ngIf="!isMobile">
                                            <li><img src="/assets/landing/img/apple.png" alt="Apple App Store" /></li>
                                            <li><img src="/assets/landing/img/android.png" alt="Android App Store" /></li>
                                        </ol>

                                        <h3>{{slide_two_beta}}</h3>

                                        <div *ngIf="userEmailSent==false">
                                          <input type="text" name="test" placeholder="you@email.com" [(ngModel)]="userEmailOne" style="width:250px;padding-left:10px;" />
                                          &nbsp;<button type="button" (click)="userBetaRegistration('userEmailTwo')" class="btn btn-primary">{{slide_two_beta_button}}</button>
                                        </div>

                                        <div *ngIf="userEmailSent==true">
                                          <span class="sent_msg">{{thank_you_message}}</span>
                                        </div>

                                    </div>

                                    <img class="slide five" src="{{slide_two_image}}" alt="iPad" />
                          			<!-- <img class="slide five" src="landing/img/slide5c.png" alt="iPad Min" /> -->
                        </li>
                      </ul>
                    </div>

                    <ul id="nav">
                      <li><span></span></li>
                      <li><span></span></li>                                            
                    </ul>
                  </div>


                <!-- END SLIDER -->

               </header>
             </div>






        <!-- START FEATURES  -->
        <div class="main-container-outer" style="position:static;width:100%;">
        

        <div class="main-container">
            <div id="features" class="main wrapper clearfix section">
            <article>

                    <!-- INSERT FEATURE TEXT  -->
                    <section>
                         
                            <img src="{{img_thumbA}}" alt="Screenshot" />
                            <!-- <img src="landing/img/thumbimage_A.png" alt="Screenshot" /> -->
                        <h2 [ngClass]="{'forceCenter' : isMobile}">
                        	{{feature_one_title}}                        	
                        </h2>

                        <p [ngClass]="{'forceCenter' : isMobile}">
                        	{{feature_one_desc}}                        	
                        </p>
                    </section>

                    <!-- INSERT FEATURE TEXT  -->
                    <section>
                        <img src="{{img_thumbB}}" alt="Screenshot" />
                      <!-- <img src="landing/img/thumbimage_B.png" alt="Screenshot" /> -->
                        <h2 [ngClass]="{'forceCenter' : isMobile}">
                        	{{feature_two_title}}                        	
                        </h2>

                        <p [ngClass]="{'forceCenter' : isMobile}">
                        	{{feature_two_desc}}                        	
                        </p>
                    </section>

                    <!-- INSERT FEATURE TEXT  -->
                    <section class="last">

                        <img src="{{img_thumbC}}" alt="Screenshot" />                      
                        <!-- <img src="landing/img/thumbimage_C.png" alt="Screenshot" /> -->
                        <h2 [ngClass]="{'forceCenter' : isMobile}">
                        	{{feature_three_title}}                        	
                        </h2>

                        <p [ngClass]="{'forceCenter' : isMobile}">
                        	{{feature_three_desc}}                        	
                        </p>
                    </section>

                </article>

        <!-- END MAIN -->
            </div>
        



           <div class="main wrapper clearfix">
              <header>
                    <div class="flexslider2 quote">
                    <ul class="slides">
                      <!-- INSERT QUOTE  -->
                        <li>"NBA - Kings vs. Warriors on April 28, 2023 -  PICK  Warriors (on April 28, 2023 @ 6:21:03 PM)"<br/>@dwsmall</li>
                      <!-- INSERT QUOTE  -->
                      <li>"NHL - Tamp Bay vs. Leafs. on April 28, 2023 -  PICK  Leafs (on April 25, 2023 @ 9:01:03 PM)"<br/>@buckstate</li>
                       <!-- INSERT QUOTE  -->
                         <li>"UFC - Yadong vs. Simón on April 29, 2023 -  PICK  Yadong (on April 19, 2023 @ 2:22:03 PM)"<br/>@freefall</li>
                         <!-- INSERT QUOTE  -->
                    </ul>
                    </div>
                 </header>
             </div>

        </div>


    </div> <!-- MAIN-CONTAINER OUTER -->



        </div>

<app-footer></app-footer>