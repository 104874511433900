import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TokenManagerService {

    lang_type!:string


	private hathTokenData:string = 'hath_app_token';   //unique_id

    private store(content:Object) {
        localStorage.setItem(this.hathTokenData, JSON.stringify(content));
    }


    private retrieve() {
        // let storedToken!:string = localStorage.getItem(this.hathTokenData);
        let storedToken = localStorage.getItem(this.hathTokenData) !== null ? localStorage.getItem(this.hathTokenData) : "";

        if(!storedToken) throw 'no token found';
        return storedToken;
    }


    public getTokenHeaders(){
        
        let p = this.getToken();

        let headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('X-Auth-Token', '2775ea68-0ece-4648-99b6-4ac2f9fe6a72');   // can be removed (irrlevant)
        headers.append('X-Requested-By', 'web');
        headers.append('Accept', 'application/json');
        return headers;
    }



    public getTokenVendorHeaders(){    

        const parsedUrl = new URL(window.location.href);
        const baseUrl = parsedUrl.origin;
        // console.log(baseUrl); // this will print http://example.com or http://localhost:4200
        // console.log('window host')
        // console.log (window.location.host);

        let baseHost = window.location.host;

        // Get all Characters after httpbefore hathlete.com

        // Determine language
        if (baseHost.includes("en-es")) {
          this.lang_type = 'es'
        } else if (baseHost.includes("es.hathlete.com")) {
          this.lang_type = 'es' 
        } else if (baseHost.includes("en-fr")) {
          this.lang_type = 'fr' 
        } else if (baseHost.includes("fr.hathlete.com")) {
          this.lang_type = 'fr' 
        } else if (baseHost.includes("en-pt")) {
          this.lang_type = 'pt' 
        } else if (baseHost.includes("pt.hathlete.com")) {
          this.lang_type = 'pt' 
        } else if (baseHost.includes("en-de")) {
          this.lang_type = 'de' 
        } else if (baseHost.includes("de.hathlete.com")) {
          this.lang_type = 'de' 
        } else if (baseHost.includes("en-it")) {
          this.lang_type = 'it' 
        } else if (baseHost.includes("it.hathlete.com")) {
          this.lang_type = 'it'
        } else if (baseHost.includes("en-cn")) {
          this.lang_type = 'cn' 
        } else if (baseHost.includes("cn.hathlete.com")) {
          this.lang_type = 'cn' 
        } else if (baseHost.includes("en-ru")) {
          this.lang_type = 'ru' 
        } else if (baseHost.includes("ru.hathlete.com")) {
          this.lang_type = 'ru' 
        } else if (baseHost.includes("en-jp")) {
          this.lang_type = 'jp' 
        } else if (baseHost.includes("jp.hathlete.com")) {
          this.lang_type = 'jp' 
        } else if (baseHost.includes("en-in")) {
          this.lang_type = 'in' 
        } else if (baseHost.includes("in.hathlete.com")) {
          this.lang_type = 'in' 
        } else if (baseHost.includes("en-ar")) {
          this.lang_type = 'ar' 
        } else if (baseHost.includes("ar.hathlete.com")) {
          this.lang_type = 'ar' 
        } else {
          this.lang_type = 'en'
        }
        
        var h = { 'X-Auth-Token': 'fcaa56d5-72af-46e8-90d7-fd4c263612a5', 'X-Requested-Language':this.lang_type, 'Access-Control-Allow-Origin': '*' }
        return h
    }



    public getTokenHeaderString(){
        var h = { 'X-Auth-Token': '10880dee-e909-4727-9171-66f946c366cb', 'X-Requested-By': '10880dee-e909-4727-9171-66f946c366cb', 'Access-Control-Allow-Origin': '*' }
        return h
    }


    // STORE TOKEN
    public storeToken(content:Object) {
        localStorage.setItem(this.hathTokenData, content.toString());
    }



    // GET TOKEN
    public getToken() {
        
        let token = null;
        try {

            let storedToken = localStorage.getItem(this.hathTokenData) !== null ? localStorage.getItem(this.hathTokenData) : "";
          
            // let tokenData = this.getDecodedAccessToken(storedToken); // decode token
            let tokenData = "hathToken123";
            let storedTokenGet = tokenData;
            token = storedTokenGet;

        }
        catch(err) {
            console.error(err);
        }
        return token;
    }



    // DELETE TOKEN ON LOGOUT
    public deleteToken() {
        localStorage.clear();
        localStorage.removeItem(this.hathTokenData);
    }


  	constructor() { }

}
