import { Component, OnInit } from '@angular/core';

import { ftr_content, IFooterContent } from '../_model/footer_content';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  privacy_url:string = "privacy & terms";

  current_cc_year:number = 2020;


  constructor() { }

  ngOnInit(): void {

  	this.current_cc_year = new Date().getFullYear()

  	// Get Language Tag (from domain url)
    var lang_tag = "en";

    //  Get Content
    var dictContent: Record<string, Partial<IFooterContent>> = ftr_content;
    
    // Populate Content
    this.privacy_url = String(dictContent[lang_tag].privacy_url);

  }

}
