import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent, PrivacyEnTermsComponent, PrivacyFrTermsComponent, PrivacyEsTermsComponent, PrivacyPtTermsComponent, PrivacyDeTermsComponent } from './privacy/privacy.component';

import { HomeComponent } from './home/home.component';
import { NotfoundComponent } from './notfound/notfound.component';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { UserApiService } from './_serviceAPI/user.api.service';
import { TokenManagerService } from './_serviceAPI/token.manager.service';
import { UserDataService } from '../app/_serviceAPI/user.data.service';




@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    FaqComponent,
    PrivacyComponent,
    PrivacyEnTermsComponent,
    PrivacyFrTermsComponent,
    PrivacyEsTermsComponent,
    PrivacyPtTermsComponent,
    PrivacyDeTermsComponent,    
    HomeComponent,
    NotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [UserApiService, UserDataService,TokenManagerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
