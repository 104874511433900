import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TokenManagerService } from './token.manager.service';
import { UserApiService } from './user.api.service';


@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(
  	private http: HttpClient,
    private tokenManager: TokenManagerService,
    private api: UserApiService
  ) {}

  createPreRegister(n_name:string, n_email:string) {
    return this.api.userCreatePreRegister(n_name, n_email)
  }

}