export interface IQuestionContent {
   question_one: string;
   question_two: string;
   question_three: string;
   question_four: string;
}

export const question_content = {
           "en": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "fr": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "es": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "it": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "pt": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "de": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "ru": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "cn": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "jp": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "hi": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"},
           "ar": { question_one: "WHAT IS A HATHLETE SCORE ?", 
           question_two: "I CANT CREATE A TEAM POOL!",
           question_three: "I CAN'T FIND A LEAGUE",
           question_four: "I CAN'T INSTALL MY APP"}
    };



export interface IAnswerContent {
   answer_one: string;
   answer_two: string;
   answer_three: string;
   answer_four: string;
}

export const answer_content = {
           "en": { answer_one: "The hathlete score is based on an algorithm that determines the awarded points for each user prediction.", 
           answer_two: "Creating of team pools can only be done using the pro-version. Modifying of existing pool information can be assigned by a pro-user.",
           answer_three: "Games and different leagues are added at differing periods. To be notified of additional games or leagues, make sure that your league notification settings are turned on.",
           answer_four: "If you are having any issues with the installation of the app on your device, we suggest insure that you check with your app store of choice (App Store or Google Play)."},
           "fr": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "es": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "it": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "pt": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "de": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "ru": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "cn": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "jp": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "hi": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"},
           "ar": { answer_one: "WHAT IS A HATHLETE SCORE ?", 
           answer_two: "I CANT CREATE A TEAM POOL!",
           answer_three: "I CAN'T FIND A LEAGUE",
           answer_four: "I CAN'T INSTALL MY APP"}
    };



export interface IVersionContent {
   version_verbage: string;
}

export const verbage_content = {
           "en": { version_verbage: "hathlete app on Android will be available soon"},
           "fr": { version_verbage: "hathlete app on Android will be available soon"},
           "es": { version_verbage: "hathlete app on Android will be available soon"},
           "it": { version_verbage: "hathlete app on Android will be available soon"},
           "pt": { version_verbage: "hathlete app on Android will be available soon"},
           "de": { version_verbage: "hathlete app on Android will be available soon"},
           "ru": { version_verbage: "hathlete app on Android will be available soon"},
           "cn": { version_verbage: "hathlete app on Android will be available soon"},
           "jp": { version_verbage: "hathlete app on Android will be available soon"},
           "hi": { version_verbage: "hathlete app on Android will be available soon"},
           "ar": { version_verbage: "hathlete app on Android will be available soon"}
    };

