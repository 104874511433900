export interface IFooterContent {
   privacy_url: string;
}

export const ftr_content = {
           "en": {privacy_url:"Privacy & Terms"},
           "fr": {privacy_url:"Privacy & Terms"},
           "es": {privacy_url:"Privacy & Terms"},
           "it": {privacy_url:"Privacy & Terms"},
           "pt": {privacy_url:"Privacy & Terms"},
           "de": {privacy_url:"Privacy & Terms"},
           "ru": {privacy_url:"Privacy & Terms"},
           "cn": {privacy_url:"Privacy & Terms"},
           "jp": {privacy_url:"Privacy & Terms"},
           "hi": {privacy_url:"Privacy & Terms"},
           "ar": {privacy_url:"Privacy & Terms"}
    };


