

<!-- Footer -->
  <div class="footer-container">
            <footer class="wrapper clearfix">

                <!-- LOGO Replace  -->
                <h1>
                    <a href="/terms" routerLinkActive="" class="hathlogo_small hath_14" target="_blank">{{privacy_url}}</a>
                    
                </h1>

                <ul class="footer-container-ul">
                    <li>&copy; hathlete.com {{current_cc_year}}
                    </li>                    
                </ul>


                <br/>
            <!-- END FOOTER -->
            </footer>


            <div style="text-align: center;">
                <ul id="langlist">
                    <!--
                    <li id="active"><a href="https://hathlete.com" id="current">english</a></li>
                    <li><a href="https://fr.hathlete.com">français</a></li>
                    <li><a href="https://es.hathlete.com">español</a></li>
                    <li><a href="https://it.hathlete.com">italiano</a></li>
                    <li><a href="https://pt.hathlete.com">português</a></li>
                    <li><a href="https://de.hathlete.com">deutsche</a></li>
                    <li><a href="https://ru.hathlete.com">русский</a></li>
                    <li><a href="https://ar.hathlete.com">ﺔﻴﺑﺮﻌﻟﺍ</a></li>
                    <li><a href="https://cn.hathlete.com">中文</a></li>
                    <li><a href="https://jp.hathlete.com">日本語</a></li>
                    <li><a href="https://in.hathlete.com">hindi</a></li>
                    -->

                </ul>
            </div>

    </div>