

<div class="row" style="margin-top: 50px;">
	<div class="col-md-12" style="text-align: center;">
		<img src="./assets/images/red_card_404.png">
		<h1>404 ERROR</h1>
		<br/>
		<h2>YOUR OUT OF BOUNDS</h2>
		<h2>THE PAGE YOU SEEK IS NOT TO BE FOUND</h2>
	</div>
</div>
