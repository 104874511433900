import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserDataService } from '../../app/_serviceAPI/user.data.service';

import { DeviceDetectorService } from 'ngx-device-detector';


/* Model */
import { MsgInfo } from './../_model/msginfo';


import { ISlideOneContent, slide_one_content, ISlideTwoContent, slide_two_content, IFeatureTitleContent, 
  feature_title_content, IFeatureDescContent, feature_desc_content,  IEmailMsgDescContent, invalid_email_content } from '../_model/body_content';

declare var window: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './sequence-slide.css', './flexslider.css', './font.css']
})
export class HomeComponent implements OnInit {

  msg_info!: MsgInfo;
  
  isMobile:boolean = false;
  

  userEmailOne:string = ""
  userEmailError:boolean = false

  userEmailSent:boolean = false

  slide_one_title:string = "Features"
  slide_one_desc:string = "F.A.Q"
  slide_one_coming_soon:string = ""
  slide_one_beta:string = ""
  slide_one_beta_button:string = ""

  slide_two_title:string = "Features"
  slide_two_desc:string = "F.A.Q"
  slide_two_coming_soon:string = ""
  slide_two_beta:string = ""
  slide_two_beta_button:string = ""

  thank_you_message:string = "";
  
  slide_one_image:string = "";
  slide_two_image:string = "";

  img_thumbA:string = "";
  img_thumbB:string = "";
  img_thumbC:string = "";

  feature_one_title:string = "";
  feature_one_desc:string = "";

  feature_two_title:string = "";
  feature_two_desc:string = "";

  feature_three_title:string = "";
  feature_three_desc:string = "";

  msg_invalid_email:string = "";

  lang_type:string = "en"


  constructor(
    private userService: UserDataService,
    private deviceService: DeviceDetectorService
  ) { }





  ngOnInit(): void {

    // Choose Language Based on Domain URL
    this.isMobile = this.deviceService.isMobile();


    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    // console.log(baseUrl); // this will print http://example.com or http://localhost:4200

    // console.log('window host')
    // console.log (window.location.host);

    let baseHost = window.location.host;


    // Get all Characters after httpbefore hathlete.com

    // Determine language
    if (baseHost.includes("en-es")) {
      this.lang_type = 'es'
    } else if (baseHost.includes("es.hathlete.com")) {
      this.lang_type = 'es' 
    } else if (baseHost.includes("en-fr")) {
      this.lang_type = 'fr' 
    } else if (baseHost.includes("fr.hathlete.com")) {
      this.lang_type = 'fr' 
    } else if (baseHost.includes("en-pt")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("pt.hathlete.com")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("en-de")) {
      this.lang_type = 'de' 
    } else if (baseHost.includes("de.hathlete.com")) {
      this.lang_type = 'de' 
    } else if (baseHost.includes("en-it")) {
      this.lang_type = 'it' 
    } else if (baseHost.includes("it.hathlete.com")) {
      this.lang_type = 'it'
    } else if (baseHost.includes("en-cn")) {
      this.lang_type = 'cn' 
    } else if (baseHost.includes("cn.hathlete.com")) {
      this.lang_type = 'cn' 
    } else if (baseHost.includes("en-ru")) {
      this.lang_type = 'ru' 
    } else if (baseHost.includes("ru.hathlete.com")) {
      this.lang_type = 'ru' 
    } else if (baseHost.includes("en-jp")) {
      this.lang_type = 'jp' 
    } else if (baseHost.includes("jp.hathlete.com")) {
      this.lang_type = 'jp' 
    } else if (baseHost.includes("en-in")) {
      this.lang_type = 'in' 
    } else if (baseHost.includes("in.hathlete.com")) {
      this.lang_type = 'in' 
    } else if (baseHost.includes("en-ar")) {
      this.lang_type = 'ar' 
    } else if (baseHost.includes("ar.hathlete.com")) {
      this.lang_type = 'ar' 
    } else {
      this.lang_type = 'en'
    }


  	this.slide_one_image = "/assets/landing/img/iphone_hand_" + this.lang_type + ".png"
  	this.slide_two_image = "/assets/landing/img/ipad_slide_" + this.lang_type + ".png"

  	this.img_thumbA = "/assets/landing/img/thumbimage_A_" + this.lang_type.toUpperCase() + ".png"
    this.img_thumbB = "/assets/landing/img/thumbimage_B_" + this.lang_type.toUpperCase() + ".png"
    this.img_thumbC = "/assets/landing/img/thumbimage_C_" + this.lang_type.toUpperCase() + ".png"

    // Get Language Tag (from domain url)
    
    //  Get Content
    var dictContent: Record<string, Partial<ISlideOneContent>> = slide_one_content;
    var dictContentS2: Record<string, Partial<ISlideTwoContent>> = slide_two_content;

    var dictContentTitle: Record<string, Partial<IFeatureTitleContent>> = feature_title_content;
    var dictContentDesc: Record<string, Partial<IFeatureDescContent>> = feature_desc_content;

    var dictContentInvalidMsg: Record<string, Partial<IEmailMsgDescContent>> = invalid_email_content;



    // Populate Content
    this.slide_one_title = String(dictContent[this.lang_type].slide_one_title);
    this.slide_one_desc =  String(dictContent[this.lang_type].slide_one_desc)
    this.slide_one_coming_soon =  String(dictContent[this.lang_type].slide_one_coming_soon)
    this.slide_one_beta =  String(dictContent[this.lang_type].slide_one_beta)
    this.slide_one_beta_button =  String(dictContent[this.lang_type].slide_one_beta_button)


    // Populate Content
    this.slide_two_title =  String(dictContentS2[this.lang_type].slide_two_title)
    this.slide_two_desc =  String(dictContentS2[this.lang_type].slide_two_desc)
    this.slide_two_coming_soon =  String(dictContentS2[this.lang_type].slide_two_coming_soon)
    this.slide_two_beta =  String(dictContentS2[this.lang_type].slide_two_beta)
    this.slide_two_beta_button =  String(dictContentS2[this.lang_type].slide_two_beta_button)


    this.feature_one_title =  String(dictContentTitle[this.lang_type].feature_one_title)
    this.feature_two_title =  String(dictContentTitle[this.lang_type].feature_two_title)
    this.feature_three_title =  String(dictContentTitle[this.lang_type].feature_three_title)

    this.feature_one_desc =  String(dictContentDesc[this.lang_type].feature_one_desc)
    this.feature_two_desc =  String(dictContentDesc[this.lang_type].feature_two_desc)
    this.feature_three_desc =  String(dictContentDesc[this.lang_type].feature_three_desc)

    this.msg_invalid_email = String(dictContentInvalidMsg[this.lang_type].email_msg_desc)



  }



  userBetaRegistration(ntype:string) {
    
    // Where is It coming From
    // console.log(ntype);

    // Validate Email Address
    if (!this.validEmail(this.userEmailOne)) {
        alert(this.msg_invalid_email);
        return;
    }

    // Submit Form with Values
    this.userService.createPreRegister("APP-DEVICE", this.userEmailOne)
            .subscribe(
                data => {

                  if (data['status'][0]['msg_status'] != "success") {

                    this.msg_info = data['status'][0]['msg_data']['msg_info'];

                    alert("ERROR - " + this.msg_info.description_text);

                  } else {
                    // alert("SUCCESS - " + data['status'][0]['msg_data']['msg_description'] );
                    this.userEmailOne = ""
                    this.userEmailSent = true
                    this.msg_info = data['status'][0]['msg_data']['msg_info'];
                    this.thank_you_message = this.msg_info.description_text

                    alert(this.thank_you_message);
                  }

                },
                error => {
                    console.log("ERROR");
                    // this.alertService.error("a network error occurred");
                    // this.loading = false;
                    alert("A Network Error Occurred");
                });

  }



  // Validate Email Address  
  validEmail(e:string) {
      var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
      return String(e).search (filter) != -1;
  }








}
