import { Injectable } from '@angular/core';
import { TokenManagerService } from '../_serviceAPI/token.manager.service';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;
// const API_URL = "https://api.hathlete.com/v01"



@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(
  	private http: HttpClient,
  	private tokenManager: TokenManagerService
  ) { }


  headers = this.tokenManager.getTokenHeaderString()
  headersVendor = this.tokenManager.getTokenVendorHeaders()


  public userCreatePreRegister(n_name:string, n_email:string) {
    {
        return this.http
        .post(API_URL + '/v01/CREATE/pre_register', {"name":n_name, "email":n_email, "appsource":"web"} , { headers: this.tokenManager.getTokenVendorHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          // return events.map((e: any) => new Brand(e));
          }),
          catchError(this.handleError)
        )                   
    }    
  }



  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }



}
