	
<header>


			
			<div *ngIf="!isMobile">
				<a href="/" class="logo">
			        <span class="hathlogo hath_44">hathlete.com</span>
			    </a>
				<ul class="menu" id="headnav">
					<li class="feat"><a href="/">{{global_features}}</a></li>
					<li class="last"><a href="/faq" target="_self">{{global_faq}}</a></li>
				</ul>
			</div>
			




			<!-- ONLY SHOW ON MOBILE -->
			<div *ngIf="isMobile">

			    <div style="margin-top:50px; text-align: center;">
			    	<a href="/" >
			    		<span class="hathlogo hath_44">hathlete.com</span>
			    	</a>	
			    </div>


			    <div style="margin-top:30px;text-align: center;">
			    	<a href="/" class="rmvlink">{{global_features}}</a>  &nbsp; &nbsp; / &nbsp; &nbsp; <a href="/faq" target="_self" class="rmvlink">{{global_faq}}</a>			    	
			    </div>
			    
			</div>


</header>




