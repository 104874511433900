

<app-header></app-header>





<div id="wrap">
        <!--[if lt IE 7]>
            <p class="chromeframe">You are using an outdated browser. <a href="http://browsehappy.com/">Upgrade your browser today</a> or <a href="http://www.google.com/chromeframe/?redirect=true">install Google Chrome Frame</a> to better experience this site.</p>
        <![endif]-->


        <!-- START HEADER  -->
        <div class="header-container">
        

            <header class="wrapper clearfix">
                	<!-- START SLIDER -->
                    <div id="header" class="internal">
                         <!-- do not touch -->
                    </div>
            </header>

        </div>

        <!-- END SLIDER -->



        <!-- START FEATURES  -->
        <div class="main-container internal">
            <div class="main wrapper clearfix section">
                <article>

                    <section>                    
                        <h2>{{question_one}}</h2>
                        <p>{{answer_one}}</p>

                        <h2>{{question_two}}</h2>
                        <p>{{answer_two}}</p>

                        <h2>{{question_three}}</h2>
                        <p>{{answer_three}}</p>

                        <h2>{{question_four}}</h2>
                        <p>{{answer_four}}</p>

                    </section>

                    <aside>
                        <img src="/assets/landing/img/slide2_A.png" alt="mockup" />
                        <p><em>
                            <span>{{version_verbage}}</span>
                            <!--hathlete app on Android 12.0 Marshmallow will be available for late 2020-->
                        </em></p>

                    </aside>

                </article>


                <!-- END MAIN -->
            </div>

        </div>

</div>







<app-footer></app-footer>