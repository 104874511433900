import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}



// Multiple Templates for Each Language

@Component({
  selector: 'privacy-terms-en',
  templateUrl: 'privacy_en.html',
})
export class PrivacyEnTermsComponent {
	constructor() { }
}



@Component({
  selector: 'privacy-terms-fr',
  templateUrl: 'privacy_fr.html',
})
export class PrivacyFrTermsComponent {
	constructor() { }
}



@Component({
  selector: 'privacy-terms-es',
  templateUrl: 'privacy_es.html',
})
export class PrivacyEsTermsComponent {
	constructor() { }
}


@Component({
  selector: 'privacy-terms-pt',
  templateUrl: 'privacy_pt.html',
})
export class PrivacyPtTermsComponent {
	constructor() { }
}


@Component({
  selector: 'privacy-terms-de',
  templateUrl: 'privacy_de.html',
})
export class PrivacyDeTermsComponent {
	constructor() { }
}