import { Component, OnInit } from '@angular/core';
import { hdr_content, IHdrContent } from '../_model/header_content';

import { DeviceDetectorService } from 'ngx-device-detector';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	
  isMobile:boolean = false;
  
  lang_type:string = "en";

  global_features:string = "Features"
  global_faq:string = "F.A.Q"

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {


    this.isMobile = this.deviceService.isMobile();

  	// Get Language Tag (from domain url)
  	
  	//  Get Content
	  var dictContent: Record<string, Partial<IHdrContent>> = hdr_content;

    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    // console.log(baseUrl); // this will print http://example.com or http://localhost:4200

    // console.log('window host')
    // console.log (window.location.host);

    let baseHost = window.location.host;

    // Get Language Tag (from domain url)


  // Determine language
    if (baseHost.includes("en-es")) {
      this.lang_type = 'es'
    } else if (baseHost.includes("es.hathlete.com")) {
      this.lang_type = 'es' 
    } else if (baseHost.includes("en-fr")) {
      this.lang_type = 'fr' 
    } else if (baseHost.includes("fr.hathlete.com")) {
      this.lang_type = 'fr' 
    } else if (baseHost.includes("en-pt")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("pt.hathlete.com")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("en-de")) {
      this.lang_type = 'de' 
    } else if (baseHost.includes("de.hathlete.com")) {
      this.lang_type = 'de' 
    } else if (baseHost.includes("en-pt")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("pt.hathlete.com")) {
      this.lang_type = 'pt' 
    } else if (baseHost.includes("en-cn")) {
      this.lang_type = 'cn' 
    } else if (baseHost.includes("cn.hathlete.com")) {
      this.lang_type = 'cn' 
    } else if (baseHost.includes("en-ru")) {
      this.lang_type = 'ru' 
    } else if (baseHost.includes("ru.hathlete.com")) {
      this.lang_type = 'ru' 
    } else if (baseHost.includes("en-jp")) {
      this.lang_type = 'jp' 
    } else if (baseHost.includes("jp.hathlete.com")) {
      this.lang_type = 'jp' 
    } else if (baseHost.includes("en-in")) {
      this.lang_type = 'in' 
    } else if (baseHost.includes("in.hathlete.com")) {
      this.lang_type = 'in' 
    } else if (baseHost.includes("en-ar")) {
      this.lang_type = 'ar' 
    } else if (baseHost.includes("ar.hathlete.com")) {
      this.lang_type = 'ar'
    } else {
      this.lang_type = 'en'
    }


	// Populate Content
	this.global_features = String(dictContent[this.lang_type].feature);
	this.global_faq = String(dictContent[this.lang_type].faq);

  }

}