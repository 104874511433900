import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


/*
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
*/

import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HomeComponent } from './home/home.component';

import { NotfoundComponent } from './notfound/notfound.component';



const routes: Routes = [

  { path: '', component: HomeComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'terms', component: PrivacyComponent},

  { path: '404', component: NotfoundComponent},
   { path: '**', redirectTo: '/404'}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
