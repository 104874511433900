export interface IHdrContent {
   feature: string;
   faq: string;
}

export const hdr_content = {
           "en": { feature: "Features", faq: "F.A.Q" },
           "fr": { feature: "Features",  faq: "F.A.Q" },
           "es": { feature: "Features",  faq: "F.A.Q" },
           "it": { feature: "Features",  faq: "F.A.Q" },
           "pt": { feature: "Features",  faq: "F.A.Q" },
           "de": { feature: "Features",  faq: "F.A.Q" },
           "ru": { feature: "Features",  faq: "F.A.Q" },
           "cn": { feature: "Features",  faq: "F.A.Q" },
           "jp": { feature: "Features",  faq: "F.A.Q" },
           "hi": { feature: "Features",  faq: "F.A.Q" },
           "ar": { feature: "Features",  faq: "F.A.Q" }
    };