export interface ISlideOneContent {
   slide_one_title: string;
   slide_one_desc: string;
   slide_one_coming_soon: string;
   slide_one_beta: string;
   slide_one_beta_button:string;
}

export const slide_one_content = {
           "en": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Go on record with your athletic predictions by having the validated hathlete stamp honoring the time", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "fr": { slide_one_title: "ENREGISTREZ VOS PRÉDICTIONS ATHLÉTIQUES", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "es": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "it": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "pt": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "de": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "ru": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "cn": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "jp": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "hi": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "ar": { slide_one_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_one_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate", 
           slide_one_coming_soon: "Coming Soon To", slide_one_beta: "Beta Registration -/- OPEN",
           slide_one_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
    };



export interface ISlideTwoContent {
   slide_two_title: string;
   slide_two_desc: string;
   slide_two_coming_soon: string;
   slide_two_beta: string;
   slide_two_beta_button: string;
}

export const slide_two_content = {"en": { slide_two_title: "TRACK SPORTING EVENTS AROUND THE GLOBE", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "fr": { slide_two_title: "SUIVEZ LES ÉVÉNEMENTS SPORTIFS DANS LE MONDE", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "es": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "it": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "pt": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "de": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "ru": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "cn": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "jp": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "hi": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
           "ar": { slide_two_title: "RECORD YOUR ATHLETIC PREDICTIONS", 
           slide_two_desc: "Use one unified interface to keep track of multiple events. From olympic competitions to collegiate",
           slide_two_coming_soon: "Coming Soon To", slide_two_beta: "Beta Registration -/- OPEN",
           slide_two_beta_button: "Sign Up", thank_you_msg: "Sign Up"},
    };




export interface IFeatureTitleContent {
   feature_one_title: string;
   feature_two_title: string;
   feature_three_title: string;
}

export const feature_title_content = {
           "en": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "fr": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "es": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "it": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "pt": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "de": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "ru": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "cn": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "jp": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "hi": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "},
           "ar": { feature_one_title: "POST YOUR ATHLETIC PREDICTIONS ", feature_two_title: "COMPETE AGAINST YOUR FRIENDS & FAMILY", 
           feature_three_title: "COMPETE WITH OTHERS ON A GLOBAL STAGE "}
    };



export interface IFeatureDescContent {
   feature_one_desc: string;
   feature_two_desc: string;
   feature_three_desc: string;
}

export const feature_desc_content = {
           "en": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},           
           "fr": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
            "es": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
            "it": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
            "pt": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
           "de": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
           "ru": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
           "cn": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
           "jp": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
           "hi": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."},
           "ar": {feature_one_desc:"Record and Post Your Prediction to Twitter, Facebook, or your favorite social networks.", 
           feature_two_desc:"Add friends and family to compete in individual or group pools using the hathlete algorithm to record your results.", 
           feature_three_desc:"Be part of hathlete history and compete for the famous Roytenberg cup which honors the longest streak of wins."}
         };


export interface IEmailMsgDescContent {
   email_msg_desc: string;
}

export const invalid_email_content = {
           "en": {email_msg_desc:"Invalid Email"},           
           "fr": {email_msg_desc:"Email invalide"},
            "es": {email_msg_desc:"Email inválido"},
            "it": {email_msg_desc:"E-mail non valido"},
            "pt": {email_msg_desc:"E-mail inválido"},
           "de": {email_msg_desc:"Ungültige E-Mail"},
           "ru": {email_msg_desc:"Неверный адрес электронной почты"},
           "cn": {email_msg_desc:"不合规电邮"},
           "jp": {email_msg_desc:"無効なメール"},
           "hi": {email_msg_desc:"अमान्य ईमेल"},
           "ar": {email_msg_desc:"بريد إلكتروني خاطئ"}
         }; 





